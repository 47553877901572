.App {
  font-family: sans-serif;
  text-align: center;
}
html,
body,
#root,
canvas {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

body {
  background: #dfdfdf;
}
